import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'

import Divider from '../components/Divider'

const Success = () => {
  return (
    <Layout>
      <section id="intro">
        <div className="text full-width">
          <h1>Your message has been sent successfully!</h1>
        </div>
      </section>
      <section>
        <div className="text" style={{ marginTop: '2rem' }}>
          <p>
            Thank you for reaching out. We will get back to you as quickly as
            possible.
          </p>
          <Link to="/" className="button" replace>
            Return to De Novo
          </Link>
        </div>
      </section>
      <Divider />
    </Layout>
  )
}

export default Success
